.processlistitem__info--bidform .btn-primary {
  color: #000;
  text-transform: uppercase;
  font-weight: 700;
  padding: 12px 27px;
  border: 0;
}

.processlistitem__info__bidform--container {
  color: #fff;
}
.processlistitem__info__bidform--container p {
  font-size: 0.75rem;
}
.processlistitem__info__bidform--container .number-input--inline > input {
  width: 100%;
  font-size: 22px;
  max-width: 240px;
}
.processlistitem__info__bidform--container .number-input__button::before {
  height: 2px;
  width: 25%;
  color: #000;
}
.processlistitem__info__bidform--container .number-input__button::after {
  height: 22%;
  width: 2px;
  color: #000;
}
.processlistitem__info__bidform--container .custom-control-input:checked ~ .custom-control-label::before {
  color: #000;
  border-color: #fff;
  background-color: #fff;
}
.processlistitem__info__bidform--container .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'><circle r='3' fill='%23000'/></svg>");
}

.processlistitem__inforow--deliveryoptions span {
  font-size: 14px;
}

.p-bids {
  margin-top: 2rem;
}
.p-bids .processlistitem__infocols {
  flex-direction: column;
}
.p-bids .processlistitem__infocol {
  flex: none;
}
.p-bids .processlistitem__infocol--extra {
  padding-left: 0 !important;
}
.p-bids .processlistitem__info--details {
  padding-bottom: 0;
}
.p-bids .processlistitem__info--bidform {
  padding-top: 0;
}
.p-bids .processlistitem__info,
.p-bids .processlistitem__link {
  max-width: 100%;
}
.p-bids .processlistitem__container--bid--highest .processlistitem__info--bidformheader {
  display: none;
}
.p-bids .processfilters__resultbar {
  display: none !important;
}