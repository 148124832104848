.p-revenue {
  margin-top: 2rem;
}
.p-revenue *[text-anchor=middle] {
  transform: translateY(10px);
  color: #fff !important;
  fill: #000;
}
.p-revenue .wrapper {
  width: 100%;
  display: block;
  overflow: hidden;
  margin: 0 auto;
}
.p-revenue #myChart {
  height: 400px;
}
.p-revenue #totalChart {
  height: 200px;
}