.p-invoices {
  margin-top: 2rem;
}
.p-invoices .col-details {
  width: 100px;
}
.p-invoices .rowborder > td {
  border-bottom: 1px solid #000;
}
.p-invoices .cellbordertop {
  border-top: 1px solid #000;
}
.p-invoices .payinvoice .material-icons {
  font-size: 16px;
  margin-right: 4px;
}