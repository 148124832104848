html.page-login h1 {
  margin: 3rem 0 1rem;
  text-align: center;
}
html.page-login .wh-wrdauth-login__forgotpasswordlinkholder {
  margin-top: 1rem;
  text-align: center;
}
html.page-login .p-login__register {
  width: 100%;
  max-width: 100%;
  margin-top: 2rem;
  text-align: center;
}
html.page-login #app {
  margin: 0;
  min-height: initial;
}
html.page-login .frmValidation {
  margin-bottom: 6px;
}
html.page-login .frmField {
  color: #495057;
  line-height: 1.25;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  border: 0;
  padding: 10px;
  border: 1px solid #dbdbdb;
  border-radius: 3px;
  width: 90%;
}
html.page-login .frmLabel {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
}
html.page-login .frmValidation {
  font-size: 13px;
  color: #d00;
}
html.page-login .frmValidation--passed {
  color: #717b85;
}
html.page-login .frmIcon {
  color: #EB0029;
  width: 14px;
}
html.page-login .frmValidation--passed {
  color: #0fa140;
}
html.page-login .frmValidation--passed .frmIcon {
  color: #0fa140;
}