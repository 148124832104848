.p-savedsearches {
  margin-top: 2rem;
}
.p-savedsearches .col-details {
  width: 100px;
}
.p-savedsearches thead {
  display: none;
}
.p-savedsearches .table-striped tbody tr:nth-of-type(even) {
  background-color: rgba(0, 0, 0, 0.1);
}
.p-savedsearches .col-actions {
  display: none;
  width: 1px;
  white-space: nowrap;
}
.p-savedsearches .col-actions > a {
  margin-left: 0.5rem;
}
.p-savedsearches .table td, .p-savedsearches .table th {
  vertical-align: middle;
}
.p-savedsearches .b-table-details > td {
  padding-top: 0;
}
@media (min-width: 576px) {
  .p-savedsearches .col-actions {
    display: table-cell;
  }
}