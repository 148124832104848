.page-calendar {
  margin-top: 2rem;
}
.page-calendar .tui-full-calendar-time-resize-handle {
  display: none !important;
}
.page-calendar #calendar.view-week .my-tui-week-dayname {
  height: 100%;
  display: flex;
  flex-direction: column;
  font-size: 0;
}
.page-calendar #calendar.view-week .tui-full-calendar-dayname-date {
  margin-top: 4px;
  font-size: 22px;
  line-height: 100%;
}
.page-calendar #calendar.view-week .tui-full-calendar-dayname-name {
  margin-top: 1px;
  font-size: 10px;
  line-height: 100%;
}
.page-calendar .tui-full-calendar-popup-vertical-line,
.page-calendar .tui-full-calendar-popup-delete {
  display: none;
}
.page-calendar .tui-full-calendar-section-button {
  display: flex;
  justify-content: center;
}
.page-calendar .c-calendar__header {
  display: flex;
  align-items: center;
  margin: 1rem 0 2rem;
  flex-flow: row wrap;
}
.page-calendar .c-calendar__header .b-form-datepicker > button {
  opacity: 0;
  pointer-events: none;
}
.page-calendar .c-calendar #renderRange {
  font-size: 20px;
  cursor: pointer;
  text-decoration: underline;
  text-underline-offset: 6px;
}
.page-calendar .c-calendar__headerpart {
  margin: 0 1rem 1rem 0;
}
.page-calendar .c-calendar__headerpart button {
  margin-right: 4px;
}
.page-calendar .c-calendar__headerpart .material-icons {
  position: relative;
  top: 3px;
  font-size: 24px;
  line-height: 100%;
}