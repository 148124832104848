.processfilters__resultbar {
  background-color: #fff;
  border-radius: 15px;
  padding: 15px 0;
  margin-bottom: 1rem;
}
.processfilters__resultbar--count {
  font-weight: bold;
  font-size: 22px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.processfilters__resultbar--count .form-select {
  width: auto;
}
.processfilters__resultbar__appliedfilters, .processfilters__resultbar--savesearch {
  font-size: 0.75rem;
}
.processfilters__resultbar__appliedfilters img, .processfilters__resultbar--savesearch img {
  margin-right: 0.4rem;
}
.processfilters__resultbar__appliedfilters--filter {
  background-color: #F6F8FB;
  border: 1px solid #E6E6E6;
  padding: 2px 16px;
  margin-right: 1rem;
  cursor: pointer;
}
.processfilters__resultbar__appliedfilters--filterclear {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.processfilters__resultbar__appliedfilters--close {
  color: #FF7B7B;
  padding-right: 4px;
}
.processfilters__resultbar__appliedfilters--text {
  text-decoration: underline;
}
.processfilters__resultbar--savesearch {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.processfilters__resultbar--savesearch--title {
  text-decoration: underline;
}
.processfilters__resultbar--savesearch .material-icons {
  color: #32BEB6;
  font-size: 1.5em;
  padding-right: 0.5rem;
}
.processfilters__mobile {
  background-color: #d3d3d3;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  padding: 50px 10px 0px 10px;
  white-space: nowrap;
  width: 100%;
  margin-top: -25px;
}
.processfilters__mobile--options {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}
.processfilters__mobile__counter {
  background-color: #fff;
  padding: 10px 10px 10px 20px;
}
.processfilters__mobile__counter span {
  background-color: #6c28a7;
  color: #fff;
  padding: 8px 14px;
  border-radius: 25px;
  margin-left: 7px;
}
.processfilters__mobile--results {
  text-align: center;
  font-size: 1.2rem;
  font-weight: 700;
}
.processfilters .material-icons {
  pointer-events: none;
}
.processfilters__heading--title {
  font-weight: bold;
  pointer-events: none;
  font-size: 18px;
}
.processfilters__label label, .processfilters__label {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.processfilters__label label .custom-control-label, .processfilters__label .custom-control-label {
  cursor: pointer;
}
.processfilters__label label--counter, .processfilters__label label--title, .processfilters__label--counter, .processfilters__label--title {
  font-size: 14px;
}
.processfilters__label label--title, .processfilters__label--title {
  font-weight: bold;
  line-height: 2;
  font-size: 14px;
}
.processfilters__label label--showall, .processfilters__label--showall {
  cursor: pointer;
}
.processfilters__label label--showall .material-icons, .processfilters__label--showall .material-icons {
  color: #32BEB6;
}
.processfilters__label label--showall .processfilters__label--title, .processfilters__label--showall .processfilters__label--title {
  text-decoration: underline;
  color: gray;
  font-weight: 400;
}
.processfilters__modal__actions {
  padding: 1rem;
  display: flex;
  background-color: #d3d3d3;
  margin-top: -15px;
}
.processfilters__modal__selectedfilters span {
  background-color: #F6F8FB;
  border: 1px solid #E6E6E6;
  padding: 2px 16px;
  cursor: pointer;
}
.processfilters__modal__options {
  text-decoration: underline;
  display: flex;
  flex-direction: column;
}
.processfilters__modal__options span {
  padding-bottom: 0.5rem;
}
.processfilters__modal--close {
  cursor: pointer;
  background-color: #FF7B7B;
  padding: 8px;
  border-radius: 30px;
  height: 27px;
  line-height: 10px;
  width: 27px;
  /* width: 33px; */
  text-align: center;
  color: #fff;
}

.processfilters__heading-filtersicon {
  transform: translateX(7px);
}

.processfilters__filtertypes {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

@media (max-width: 575px) {
  #modal-processdetails .modal-dialog {
    margin: 0;
  }
}

#modal-processdetails .modal-header {
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
  z-index: 99999;
  background-color: #fff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
}

#modal-processdetails .modal-header .close {
  font-size: 2.5rem;
  padding-top: 0;
  padding-bottom: 0;
}

#modal-processdetails .modal-body {
  margin-top: -25px;
}

#modal-mobilefilters .modal-body {
  padding: 0;
}
#modal-mobilefilters .processfilters__filtertypes {
  padding: var(--bs-modal-padding);
}
#modal-mobilefilters .processfilters__modal__actions {
  width: 100%;
  margin-left: 0;
  margin-top: 0;
}
#modal-mobilefilters .processfilters__modal__options a {
  color: inherit;
}