.p-edit-my-data {
  padding-left: 1rem;
  padding-right: 1rem;
  margin: 0 auto;
  width: 100%;
  max-width: calc(1200px + 2rem);
  margin-top: 2rem;
}
.p-edit-my-data .wh-form {
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}