@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css");
.btn-primary,
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary.focus,
.btn-primary:focus,
.btn-primary.disabled,
.btn-primary:disabled {
  background-color: #f6a713;
  border-color: #f6a713;
}
.btn-primary:hover,
.btn-primary:not(:disabled):not(.disabled):active:hover,
.btn-primary.focus:hover,
.btn-primary:focus:hover,
.btn-primary.disabled:hover,
.btn-primary:disabled:hover {
  background: rgb(204.212244898, 135.8530612245, 7.787755102);
  border-color: rgb(204.212244898, 135.8530612245, 7.787755102);
}

.btn-primary.disabled,
.btn-primary:disabled {
  opacity: 0.4;
  pointer-events: none;
}

.btn-secondary,
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary.focus,
.btn-secondary:focus,
.btn-secondary.disabled,
.btn-secondary:disabled {
  background-color: #4679a5;
  border-color: #4679a5;
}
.btn-secondary:hover,
.btn-secondary:not(:disabled):not(.disabled):active:hover,
.btn-secondary.focus:hover,
.btn-secondary:focus:hover,
.btn-secondary.disabled:hover,
.btn-secondary:disabled:hover {
  background: rgb(56, 96.8, 132);
  border-color: rgb(56, 96.8, 132);
}

.btn-outline-primary {
  color: #f6a713;
  border-color: #f6a713;
}
.btn-outline-primary:hover {
  background-color: #f6a713;
  color: #fff;
  border-color: #f6a713;
}

.btn-danger {
  color: #fff;
  background-color: #ec6446;
  border-color: #ec6446;
}

.text-danger {
  color: #ec6446 !important;
}

.custom-select {
  cursor: pointer;
}

.button,
.wh-form__button {
  background: #f6a713;
  display: flex;
  align-items: center;
  padding: 20px 40px;
  position: relative;
  overflow: hidden;
  font-size: 20px;
  color: #0E193C;
  font-weight: 700;
  font-size: 14px;
  transition: color 0.3s, background 0.3s;
  border: 1px solid #f6a713;
  cursor: pointer;
}
.button:hover,
.wh-form__button:hover {
  background: #0E193C;
  color: #f6a713;
}

.btn-primary {
  border: 0;
}

.p-filters__objecttype--aboveform {
  margin-bottom: 1rem;
}
@media (min-width: 768px) {
  .p-filters__objecttype--aboveform {
    position: absolute;
    right: 0;
    top: -70px;
  }
}
.p-filters__grid {
  width: 100%;
  display: grid;
  flex-flow: row wrap;
  grid-template-columns: 1fr;
  gap: 1rem;
}
@media (min-width: 576px) {
  .p-filters__grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 768px) {
  .p-filters__grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 992px) {
  .p-filters__grid {
    grid-template-columns: repeat(4, 1fr);
  }
}
.p-filters__grid > * {
  display: flex;
  align-items: center;
}
.p-filters__grid label {
  margin-bottom: 0;
}
.p-filters__grid label + .custom-select,
.p-filters__grid .custom-select + .custom-select {
  margin-left: 8px;
}
.p-filters__filter {
  display: flex;
  align-items: center;
}

:root {
  --app-background-color: #E6EDF5;
  --color-green: #32BEB6;
  --color-blue: #0e193c;
  --color-orange: #f6a713;
  --color-purple: #6c28a7;
  --color-yellow: #FFE300;
  --color-pink: #FF7B7B;
  --button-height: 50px;
}

.nav {
  --bs-link-color: #000;
}

*, *:before, *:after {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

.vr {
  opacity: 1;
}

.form-select {
  cursor: pointer;
}

.form-check-label {
  cursor: pointer;
}

input[type=text],
input[type=password],
input[type=email] {
  border-radius: var(--bs-border-radius-pill);
  height: var(--button-height);
}

.btn .bi {
  margin-right: 8px;
}

.btn.btn--icon-right .bi {
  margin-left: 8px;
  margin-right: 0;
}

.btn-link {
  padding-left: 0;
  padding-right: 0;
}

.btn--orange,
.btn--purple,
.btn--yellow,
.btn--green {
  padding: 0 1.5rem;
  border-radius: var(--bs-border-radius-pill) !important;
  color: #fff !important;
  line-height: 100%;
  font-weight: 700;
  font-size: 1rem;
  min-height: var(--button-height);
  display: inline-flex;
  align-items: center;
}

.btn--green {
  background: var(--color-green);
}
.btn--green:focus, .btn--green:active {
  background: rgb(40, 152, 145.6);
}
@media (hover: hover) {
  .btn--green:hover {
    background: rgb(40, 152, 145.6);
  }
}

.btn--orange {
  background: var(--color-orange);
}
@media (hover: hover) {
  .btn--orange:hover {
    background: rgb(204.212244898, 135.8530612245, 7.787755102);
  }
}

.btn--yellow {
  background: var(--color-yellow);
  color: #000 !important;
}
@media (hover: hover) {
  .btn--yellow:hover {
    background: rgb(204, 181.6, 0);
  }
}

.btn--purple {
  background: var(--color-purple);
  color: #fff !important;
}
@media (hover: hover) {
  .btn--purple:hover {
    background: rgb(86.4, 32, 133.6);
  }
}

*[data-bs-toggle].collapsed .bi-chevron-down,
.bi-chevron-down[aria-expanded=true] {
  transform: scaleY(-1);
}

body {
  color: #000;
}
html.page-details body {
  background: #E6EDF5;
}

body {
  display: none;
  overflow-y: scroll;
}
body.init {
  display: block;
}
html.init body, html.wh-errorpage body {
  display: block;
}

.form-group {
  margin: 0;
}

.view {
  padding-left: 1rem;
  padding-right: 1rem;
  margin: 0 auto;
  width: 100%;
  max-width: calc(1200px + 2rem);
}
.view.mdl-layout__header-row {
  padding-left: 0;
  padding-right: 0;
}
@media screen and (max-width: 1200px) {
  .view.mdl-layout__header-row {
    padding-left: 12px;
    padding-right: 12px;
  }
}

.p-filters,
.processfilters__resultbar,
.processesgrid {
  margin-top: 1rem;
}

.processesgrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 30px 20px;
}
.processesgrid .processlistitem__info__bidform--container {
  padding: 0;
}
.processesgrid .processlistitem__info--bidform .processlistitem__inforow--bidinput {
  margin: 0 !important;
}
.processesgrid .processlistitem__info--bidform .processlistitem__info--bidformheader, .processesgrid .processlistitem__info--bidform .processlistitem__info--highestbid, .processesgrid .processlistitem__info--bidform .processlistitem__inforow--deliveryoptions {
  margin-bottom: 0 !important;
}
.processesgrid .bidform__removebidbuttoncontainer {
  margin-top: 12px;
}
@media (min-width: 576px) {
  .processesgrid {
    gap: 45px 30px;
  }
}
@media (min-width: 1200px) {
  .processesgrid {
    gap: 60px 40px;
  }
  .processesgrid:not(.processesgrid--fullwidth) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

select.mdl-textfield__input {
  height: 30px;
}

.mdl-layout__header {
  background: #fff;
  color: #000;
}
.mdl-layout__header .material-icons {
  color: #000;
}

.mdl-layout__drawer {
  color: #fff;
}
.mdl-layout__drawer .mdl-layout-title {
  position: relative;
  height: 100px;
  display: flex;
  align-items: flex-end;
}

.number-input__button {
  cursor: pointer;
}

.sitewrapper {
  max-width: 1000px;
  margin: 0 auto;
  width: 100%;
  margin-bottom: 60px;
  padding: 0 25px;
}

h2 {
  line-height: 1.3em;
}

.custom-radio .custom-control-label > span {
  margin-top: 3px;
  display: block;
}

.custom-control.custom-radio > * {
  cursor: pointer;
}

.custom-radio.b-custom-control-lg, .input-group-lg .custom-radio {
  font-size: 1rem;
}

.wh-form__fieldgroup--checkbox .wh-form__fieldline {
  display: flex;
}

.formulate-input[data-classification=text] input {
  background: #fff;
}