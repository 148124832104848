.header__logo {
  height: 35px;
}
@media (min-width: 768px) {
  .header__logo {
    height: 50px;
  }
}
.header__loggedinas {
  margin-right: 10px;
}
.header__morebuttoncontainer {
  position: relative;
}
.header__dropdown button {
  padding: 0;
  line-height: 100%;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 600px) {
  .header__loggedinas {
    display: none !important;
  }
  .mdl-layout__header-row {
    padding-left: 16px;
  }
}