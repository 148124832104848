html.hide-update-saved-search-form-type .updatesavedsearchform-name {
  display: none;
}

.fade-enter-active, .fade-leave-active {
  transition-property: opacity;
  transition-duration: 0.25s;
}

.fade-enter-active {
  transition-delay: 0.25s;
}

.fade-enter, .fade-leave-active {
  opacity: 0;
}

@media (min-width: 992px) {
  .p-cars {
    display: grid;
    grid-template-columns: 270px 1fr;
    gap: 50px;
  }
}
.p-cars .custom-control.custom-radio.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.p-cars .c-sliding-pagination__list {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.p-cars .c-sliding-pagination__list-element {
  margin-right: 9px;
  padding: 0;
  display: inline-flex;
}
.p-cars .c-sliding-pagination__list-element a {
  padding: 10px 15px;
}