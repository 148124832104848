.processlistitem {
  padding-left: 1rem;
  padding-right: 1rem;
  margin: 0 auto;
  width: 100%;
  max-width: calc(1200px + 2rem);
}
.processlistitem__details {
  font-size: 14px;
}
.processlistitem__details .processlistitem__inforow {
  gap: 16px;
}
.processlistitem__details .fas {
  background-color: #32BEB6;
}
.processlistitem__notification {
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
  z-index: 100;
}
.processlistitem__deliverydatenative {
  display: flex;
  align-items: center;
}
.processlistitem__deliverydatenative button:nth-child(2) {
  margin-left: 5px;
}
.processlistitem__cleardeliverydate {
  cursor: pointer;
}
.processlistitem__deliveryinput {
  padding: 7px 5px;
  cursor: pointer;
}
.processlistitem__image {
  height: 175px;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
}
.processlistitem__image--noimg {
  background-image: url("../../web/img/car-placeholder.svg");
  background-size: 50%;
  background-position: center;
}
.processlistitem__image--comment {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #32BEB6;
  color: #fff;
  padding: 2px 10px;
  border-radius: 15px;
  font-size: 0.75rem;
}
.processlistitem__image--imagescount {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: #fff;
  color: #000;
  padding: 5px 12px;
  border-radius: 15px;
  font-size: 0.75rem;
  align-items: center;
}
.processlistitem__image--imagescount img {
  margin-right: 0.4rem;
}
.processlistitem__image--icon {
  position: absolute;
  color: #fff;
  height: 24px;
  width: 24px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.processlistitem__image--icon img {
  width: 25px;
}
.processlistitem__image--campaign {
  top: 10px;
  right: 40px;
}
.processlistitem__image--favorite {
  top: 10px;
  right: 10px;
}
.processlistitem__infocols--nophoto {
  display: block;
}
.processlistitem__infocols--nophoto .processlistitem__image--imagescount {
  display: none !important;
}
.processlistitem__infocols--nophoto .processlistitem__salescontractlink {
  display: inline-block;
  margin-bottom: 1rem;
}
.processlistitem__infocol {
  flex: 1;
  background: #fff;
}
.processlistitem__infocol--extra {
  color: #fff;
  padding: 23px 20px 30px;
  background-color: #6c28a7;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}
.processlistitem__info {
  position: relative;
  word-break: break-word;
  width: 100%;
}
.processlistitem__info--details {
  width: 100%;
}
.processlistitem__info--details a {
  color: inherit;
}
.processlistitem__info--details a.processlistitem__salescontractlink {
  color: #4679a5 !important;
}
.processlistitem__info:nth-child(n+2) {
  margin-top: 1rem;
}
.processlistitem__infotitle {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 0;
}
.processlistitem__infotitle--license {
  font-weight: normal;
  margin-bottom: 0.5rem;
}
.processlistitem__infolabel {
  margin-right: 24px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.processlistitem__link {
  color: inherit;
  text-decoration: none;
  transition: color 0.3s;
  display: inline-block;
  max-width: calc(100% - 30px);
}
.processlistitem__link:hover {
  color: rgb(247, 168, 23);
}
@media screen and (max-width: 768px) {
  .processlistitem__infocols {
    flex-direction: column;
  }
  .processlistitem__infocols .processlistitem__infocol {
    margin-top: 1rem;
  }
  .processlistitem__info {
    padding-bottom: 0;
  }
  .processlistitem__info--wider {
    min-width: 230px;
  }
  .processlistitem__image {
    width: 100%;
    aspect-ratio: 16/9;
    height: auto;
  }
}

.processlistitem__info--details {
  background: #fff;
}

.processlistitem__inforow {
  margin-top: 14px;
  justify-content: space-between;
}

.processlistitem__inforow--deliveryoptions {
  display: flex;
  justify-content: center;
}

.processlistitem__infolabel {
  display: flex;
  align-items: center;
  margin-right: 12px;
}
.processlistitem__infolabel input {
  margin-right: 4px;
}

.processlistitem__--price {
  font-weight: 700;
}

.processlistitem__container {
  display: flex;
  flex-direction: column;
  margin: 0 0 15px;
  transition: background 0.3s;
  text-decoration: none;
  color: inherit;
  position: relative;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.16);
}
.processlistitem__container--bid--highest a:hover, .processlistitem__container--bid--nothighest a:hover {
  color: inherit;
}
.processlistitem__container--bid--nothighest .processlistitem__info--details {
  color: #ec6446;
}
.processlistitem__container--bid--highest .processlistitem__info--details {
  color: #32BEB6 !important;
}
.processlistitem__container .fas {
  padding: 7px;
  border-radius: 25px;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 11px;
  color: #fff;
}

.processlistitem__container--showbidform {
  border-radius: 5px;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  border: 1px solid #c2c2c2;
}

.processlistitem__logo {
  position: absolute;
  bottom: 15px;
  right: 15px;
}

.processlistitem__logoimg {
  display: none;
}

.processlistitem__logoimg--wvuaw {
  height: 30px;
}

.processlistitem__logoimg--looping {
  height: 30px;
}

.processlistitem__container--looping .processlistitem__logoimg--looping,
.processlistitem__container--wvuaw .processlistitem__logoimg--wvuaw {
  display: inline;
}

.processlistitem__info--bidformheader {
  color: #FFE300;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 26px;
  line-height: 100%;
}

.processlistitem__info__bidform--container input {
  font-weight: 800;
}

.processlistitem__info--bidform .processlistitem__info--bidformheader,
.processlistitem__info--bidform .processlistitem__info--highestbid,
.processlistitem__info--bidform .processlistitem__inforow--deliveryoptions {
  margin-bottom: 12px !important;
}
.processlistitem__info--bidform .processlistitem__inforow--bidinput {
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}

@media (hover: hover) {
  .processlistitem__processlink:hover {
    text-decoration: underline;
  }
}

.processlistitem__expandicon {
  margin-left: 1rem;
  font-size: 1.5rem;
  background: red;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: L row;
  border-radius: 50%;
  flex: 0 0 32px;
  height: 32px;
  background: var(--color-purple);
}
.processlistitem__expandicon .bi-chevron-down[aria-expanded=true] {
  margin-top: -1px;
}
.processlistitem__expandicon i {
  margin-top: 4px;
  color: #fff;
}

@media (max-width: 653px) {
  .processlistitem__infocol--details,
  .processlistitem__infocol--extra {
    min-height: initial !important;
  }
}

.processesgrid--deals .processlistitem__infocol--extra {
  background: none;
  color: #000;
}
.processesgrid--deals .processlistitem__dealspageinfocontainer {
  margin: 1rem 0 0;
}
.processesgrid--deals .processlistitem__dealspageinfocontainer::first-child {
  margin-top: 2rem;
}
.processesgrid--deals .processlistitem__dealspageprices {
  font-size: 0.85rem;
  display: flex;
  flex-flow: row wrap;
  gap: 0.3rem 1rem;
}
.processlistitem__image-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}