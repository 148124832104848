html.page-unsubscribe body {
  display: block;
}
html.page-unsubscribe .wh-form {
  max-width: 100%;
}
html.page-unsubscribe .wh-form__prefillarea {
  display: none;
}
html.page-unsubscribe .wh-form__page[data-wh-form-pagerole=thankyou] {
  font-weight: bold;
  color: green;
}