@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700;800;900&display=swap");
#app {
  overflow: hidden;
  min-height: 100vh;
  background-color: var(--app-background-color);
}

body, #app, #modal-processdetails {
  font-family: Raleway, "Raleway", BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.vld-overlay.is-full-page {
  z-index: 99999 !important;
}

#app-header-navbar .navbar-nav,
html.page-details .navbar-nav {
  gap: 16px;
}
#app-header-navbar .navbar,
html.page-details .navbar {
  background: #fff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  height: 75px;
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
}
#app-header-navbar .navbar .nav,
html.page-details .navbar .nav {
  height: 75px;
}
#app-header-navbar .navbar .nav-link,
html.page-details .navbar .nav-link {
  padding: 0 !important;
  display: inline-block;
  color: #000 !important;
  font-weight: 500;
  transition: color 0.3s;
}
@media (hover: hover) {
  #app-header-navbar .navbar .nav-link:hover,
  html.page-details .navbar .nav-link:hover {
    color: var(--color-purple) !important;
  }
}
#app-header-navbar .navbar .navmobile--search .material-icons,
html.page-details .navbar .navmobile--search .material-icons {
  font-size: 2rem;
}
#app-header-navbar .navbar .topnav .nav-link,
html.page-details .navbar .topnav .nav-link {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.5rem;
  font-weight: 400;
  font-size: 12px;
}
#app-header-navbar .navbar .topnav img,
html.page-details .navbar .topnav img {
  width: 0.75rem;
  margin-right: 0.4rem;
}
@media (min-width: 992px) {
  #app-header-navbar .navbar,
  html.page-details .navbar {
    height: 100px;
  }
  #app-header-navbar .navbar .nav,
  html.page-details .navbar .nav {
    height: 100px;
  }
}

html.page-app #normalheader {
  display: none;
}

.editprocessmodal {
  border: 0;
  padding: 3rem;
  width: 500px;
  max-width: 90vw;
}

.modal-editprocessmodal .modal-body {
  background-color: #fff;
}

.modal-body {
  background-color: var(--app-background-color);
}
.modal-body #process-details {
  margin-top: 0 !important;
}
.modal-body .formulate-input .formulate-input-element.formulate-input-element--textarea {
  max-width: 100%;
  height: 60px;
}
.modal-body .formulate-input .formulate-input-element.formulate-input-element--textarea textarea {
  height: 100%;
  resize: none;
}
.modal-body .formulate-input-label--before {
  font-size: 20px;
}

.vm--modal {
  overflow: visible !important;
  padding: 2rem;
}

.editprocessmodal__deliverydate {
  display: flex;
}

.editprocessmodal__cleardeliverydate {
  display: inline-flex;
  align-items: flex-end;
  padding-bottom: calc(1.5em - 7px);
  cursor: pointer;
}

#swal2-content {
  line-height: 135%;
}

.header__mobile--hamburger {
  overflow-anchor: none;
  height: 38px;
  padding: 0;
  border: none;
}
.header__mobile--hamburger .material-icons {
  font-size: 2.4em;
  font-weight: 700;
}

.header__searchbar--input {
  border-top-left-radius: 12em;
  border-bottom-left-radius: 12em;
}
.header__searchbar--button {
  border-top-right-radius: 12em;
  border-bottom-right-radius: 12em;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  background-color: #fff;
  border-left: none;
  border-color: #ced4da;
}
.header__searchbar--button:hover, .header__searchbar--button:focus, .header__searchbar--button:active {
  background-color: #fff !important;
  border-color: #ced4da !important;
}
.header__searchbar--button .material-icons {
  color: #000;
}

/* Vertical divider */
.vr {
  border-left: 1px solid #32BEB6;
  padding: 0;
  height: 15px;
  align-self: center;
}