.page-deals {
  margin-top: 2rem;
}
.page-deals .p-car__notification {
  top: auto;
  bottom: 15px;
}
.page-deals.p-cars {
  display: block;
}
.page-deals .processfilters__resultbar {
  display: none !important;
}
.page-deals .c-processes--filter-all .processlistitem__image {
  display: none !important;
}
.page-deals .c-dealinforow {
  --label-width: 120px;
  margin: 1rem 0;
  display: flex;
}
.page-deals .c-dealinforow .c-dealinforow__label {
  width: var(--label-width);
}
.page-deals .c-dealinforow--prices {
  flex-direction: column;
  gap: 8px;
}
.page-deals .c-dealinforow--prices .c-dealinforow__label {
  width: auto;
}
.page-deals .c-processimages {
  display: none;
}
@media (min-width: 992px) {
  .page-deals .c-search {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5rem;
  }
  .page-deals .c-search__status {
    font-weight: 500;
  }
  .page-deals .c-search__statusform {
    display: flex;
    gap: 1rem;
    margin-bottom: 0 !important;
  }
  .page-deals .c-search__textfilterform {
    flex: 1;
  }
  .page-deals .c-dealinforow {
    --label-width: 150px;
  }
  .page-deals .c-dealinforow--deliverystatus {
    margin-bottom: 0;
  }
  .page-deals .processlistitem__info--dealspage {
    margin-top: 0 !important;
  }
  .page-deals .processlistitem__info--prices {
    order: 10;
  }
  .page-deals .processlistitem__infocol--dealspage {
    display: grid;
    grid-template-columns: 250px 1fr;
    gap: 0;
    min-height: initial !important;
  }
  .page-deals .c-dealinforow--prices {
    margin-top: 0;
    flex-direction: row;
    align-items: center;
    gap: 0;
    margin-bottom: 0;
  }
  .page-deals .c-dealinforow--prices .c-dealinforow__label {
    width: var(--label-width);
  }
  .page-deals .c-dealinforow--deliverystatus {
    order: 5;
    margin-top: 0;
    margin-bottom: 0;
  }
  .page-deals .processesgrid--deals .processesgrid {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
  .page-deals .processesgrid--deals .processlistitem__expandicon {
    display: none;
  }
  .page-deals .processesgrid--deals .processlistitem__downloadsalescontractbutton {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
  .page-deals .processesgrid--deals .accordion-collapse {
    display: block !important;
  }
  .page-deals .processesgrid--deals .processlistitem__editbutton {
    position: absolute;
    top: auto;
    right: 1rem;
    bottom: 1rem;
    display: inline-block;
  }
  .page-deals .processesgrid--deals .processlistitem__image {
    display: none !important;
  }
  .page-deals .processesgrid--deals .c-processimages {
    display: block;
  }
  .page-deals .processesgrid--deals .c-processimages .processlistitem__image {
    display: block !important;
    width: 100%;
    height: 100%;
  }
  .page-deals .processesgrid--deals .processlistitem__dealspageinfocollapse {
    display: flex !important;
    flex-direction: column;
  }
}