#modal-processdetails .modal-header {
  min-height: 80px;
}
#modal-processdetails .modal-header .c-nav__navitem {
  font-size: 2.5rem;
  color: #f6a713;
  transition: color 0.3s, opacity 0.3s;
  opacity: 0.5;
  pointer-events: none;
}
@media (hover: hover) {
  #modal-processdetails .modal-header .c-nav__navitem:hover {
    cursor: pointer;
    color: #4679a5;
  }
}
#modal-processdetails .modal-header .c-nav__navitem.active {
  opacity: 1;
  pointer-events: auto;
}

.odd {
  background-color: #fff;
}

.p-cardetails {
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-bottom: 8rem;
}
.p-cardetails #mapid {
  width: 100%;
  height: 380px;
}
.p-cardetails .splide__arrow svg {
  fill: #32BEB6;
}
.p-cardetails .splide__arrow {
  background: #fff;
  opacity: 1;
}
.p-cardetails .splide__arrow:disabled {
  opacity: 1;
}
.p-cardetails a {
  cursor: pointer;
}
.p-cardetails .processlistitem__image--icon .material-icons {
  font-size: 1.5rem;
  height: 24px;
}
.p-cardetails__header {
  gap: 0 2rem;
  padding: 0;
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .p-cardetails__header {
    display: grid;
    grid-template-areas: "photos details" "container container";
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: 992px) {
  .p-cardetails__header {
    gap: 0 40px;
    grid-template-columns: 500px 1fr;
  }
}
.p-cardetails__header .p-cardetails__photos {
  grid-area: photos;
}
.p-cardetails__header .p-cardetails__header__details {
  grid-area: details;
}
.p-cardetails__header .p-cardetails__container {
  grid-area: container;
}
.p-cardetails__header .footer {
  grid-area: ft;
}
.p-cardetails__header .content {
  grid-area: main;
}
.p-cardetails__header .sidebar {
  grid-area: sd;
}
.p-cardetails__header__details--model {
  font-size: 36px;
  font-weight: bold;
  word-break: break-word;
}
.p-cardetails__header__details--license {
  font-size: 36px;
  font-weight: normal;
}
.p-cardetails__header__details--type {
  font-size: 20px;
}
.p-cardetails__header__details .processlistitem__inforow {
  font-size: 14px;
  gap: 16px;
}
.p-cardetails__header__details .processlistitem__inforow--pricelabel {
  margin-top: 1rem;
  font-size: 20px;
}
.p-cardetails__header__details .processlistitem__inforow--price {
  font-size: 20px;
  font-weight: bold;
}
.p-cardetails__header__details .processlistitem__info--bidformheader {
  font-size: 38px;
}
.p-cardetails__header__details .processlistitem__info__bidform--container p {
  font-size: 14px;
}
.p-cardetails__header__details .p-cardetails__header__details .processlistitem__inforow {
  font-size: 16px;
}
.p-cardetails__header__details--contact {
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 700;
}
.p-cardetails__header__details--contact:hover {
  text-decoration: none;
  color: #fff;
}
.p-cardetails__header__details--contact .fab, .p-cardetails__header__details--contact .fas {
  padding-right: 5px;
}
.p-cardetails__header__details--location {
  text-decoration: none;
  color: inherit;
}
.p-cardetails__header__details--location .fas {
  padding: 7px;
  border-radius: 25px;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 11px;
  color: #fff;
  background-color: #32BEB6;
  font-size: 0.85rem;
}
.p-cardetails__header__details--whatsapp {
  background-color: #32BEB6;
}
.p-cardetails__header__details--phone {
  background-color: #6c28a7;
  display: flex;
}
.p-cardetails__header__details--whatsapp, .p-cardetails__header__details--phone {
  height: 50px;
  display: inline-flex;
  align-items: center;
}
.p-cardetails__header__details--whatsapp svg,
.p-cardetails__header__details--whatsapp img, .p-cardetails__header__details--phone svg,
.p-cardetails__header__details--phone img {
  margin-right: 10px;
}
@media (min-width: 768px) {
  .p-cardetails__expectedprice-bidform {
    display: flex;
    column-gap: 1rem;
  }
  .p-cardetails__expectedprice-bidform > * {
    flex: 0 0 50%;
  }
}
.p-cardetails__history-map #cardetails-map-heading {
  transform: translateY(-50px);
}
.p-cardetails__history-map--heading {
  display: flex;
  align-items: center;
  margin-bottom: 2em;
}
@media screen and (max-width: 1000px) {
  .p-cardetails__history-map--heading {
    display: block;
  }
}
.p-cardetails__history-map--heading > * {
  margin-right: 1em;
  margin-bottom: 0;
}
.p-cardetails__history-map--heading button {
  background-color: #6c28a7;
  border-color: #6c28a7;
  font-weight: bold;
  text-transform: uppercase;
  padding: 14px 24px;
  font-size: 16px;
}
.p-cardetails__photos {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  overflow: hidden;
}
.p-cardetails__photos--hasphotos {
  background: #ddd;
}
.p-cardetails__photos #main-carousel .processlistitem__image--imagescount {
  bottom: 20px;
}
.p-cardetails__photos #main-carousel .splide__list {
  width: 100%;
  aspect-ratio: 16/9;
}
@media (min-width: 768px) {
  .p-cardetails__photos #main-carousel .splide__list {
    aspect-ratio: 1/1;
  }
}
.p-cardetails__photos #main-carousel .splide__slide img {
  object-fit: cover;
  /* object-position: center; */
  width: 100%;
  height: 100%;
}
.p-cardetails__photos #main-carousel .splide__slide {
  opacity: 1;
}
.p-cardetails__photos .splide__track--nav > .splide__list > .splide__slide.is-active {
  border-color: transparent;
}
.p-cardetails__photos .splide__slide.is-active {
  opacity: 1;
}
.p-cardetails__photos a {
  width: 100%;
}
.p-cardetails__photos .processlistitem__image--over {
  font-size: 14px;
}
.p-cardetails .processlistitem__image--over {
  z-index: 1;
}
.p-cardetails .processlistitem__image--campaign {
  right: 52px;
}
.p-cardetails .processlistitem__image--icon {
  padding: 0;
  border-radius: 20px;
  width: auto;
}
.p-cardetails .processlistitem__image--icon img {
  width: 36px;
}
.p-cardetails .processlistitem__image--imagescount {
  padding: 10px;
  border-radius: 25px;
}
.p-cardetails .processlistitem__image--magnify {
  background-color: transparent;
  right: 0;
  padding: 0;
  pointer-events: none;
}
.p-cardetails__imgel {
  height: 100%;
}
.p-cardetails__morephotos {
  background: rgba(246, 167, 19, 0.85);
  color: #fff;
  font-size: 22px;
  position: absolute;
  right: 15px;
  top: 50%;
  padding: 8px;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}
.p-cardetails__morephotos::after {
  content: "";
  padding-top: 100%;
}
.p-cardetails__licensecontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 25px 0;
}
.p-cardetails__licensecontainer > * {
  margin: 0 10px;
}
.p-cardetails__licensecontainer > *:first-child {
  margin-left: 0;
}
.p-cardetails__licensecontainer > *:last-child {
  margin-right: 0;
}
.p-cardetails__licensecontainer .material-icons {
  font-size: 26px;
}
.p-cardetails__licensecontainer .fa-whatsapp {
  font-size: 34px;
  color: #25D366;
}
.p-cardetails__license {
  text-align: center;
  font-weight: 700;
  font-size: 24px;
  background-color: #ffc938;
  padding: 10px 30px;
  border: 1px solid #000;
  border-radius: 4px;
}
.p-cardetails__info {
  margin-top: 1.5rem;
}
.p-cardetails__info--heading {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}
.p-cardetails__info > section {
  padding-bottom: 20px;
  margin-top: 1.5rem;
  margin-bottom: 22px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.p-cardetails__info > section:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}
.p-cardetails__info--distance {
  font-size: 18px;
}
.p-cardetails__cols--2col {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.p-cardetails__cols--2col .p-cardetails__inforow:nth-child(2n) {
  margin-left: 1rem;
}
.p-cardetails__cols--2col .p-cardetails__inforow:nth-child(2n-1) {
  margin-right: 1rem;
}
.p-cardetails__cols--2col .p-cardetails__inforow:nth-child(4n-3),
.p-cardetails__cols--2col .p-cardetails__inforow:nth-child(4n-2) {
  background: #fff;
}
.p-cardetails__col {
  width: 50%;
}
.p-cardetails__inforow {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  padding: 1rem;
}
.p-cardetails__inforow--odd {
  background-color: #fff;
}
.p-cardetails .processlistitem__bidbutton {
  background: #FFE300;
  color: #000;
  text-transform: uppercase;
  font-weight: 700;
}
.p-cardetails__infolabel {
  font-weight: bold;
  word-break: break-word;
}
.p-cardetails__infolabel, .p-cardetails__infovalue {
  font-size: 18px;
  width: 50%;
}
.p-cardetails__options {
  columns: 2;
  column-gap: 2rem;
  background-color: #fff;
  padding: 1rem;
  line-height: 150%;
}
@media screen and (max-width: 1000px) {
  .p-cardetails__cols {
    flex-direction: column;
  }
  .p-cardetails__cols--2col {
    columns: 1;
    grid-template-columns: 1fr;
  }
  .p-cardetails__cols--2col .p-cardetails__inforow {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .p-cardetails__cols--2col .p-cardetails__inforow:nth-child(4n-3), .p-cardetails__cols--2col .p-cardetails__inforow:nth-child(4n-2) {
    background-color: transparent;
  }
  .p-cardetails__cols--2col .p-cardetails__inforow:nth-child(2n-1) {
    background-color: #fff;
  }
  .p-cardetails__col {
    width: 100%;
  }
  .p-cardetails__options {
    columns: 1;
  }
}
.p-cardetails__nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.p-cardetails__snackbar {
  display: flex;
  align-items: center;
  bottom: 10px;
  height: 95px;
  width: 100%;
  padding: 10px 20px 10px 20px;
}
.p-cardetails__snackbar--container {
  background-color: var(--color-purple);
  width: 100%;
  position: fixed;
  height: 95px;
  bottom: 0;
  left: 0;
  z-index: 1000;
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.25);
}
.p-cardetails__snackbar__details {
  display: flex;
  height: 100%;
  padding: 0;
}
.p-cardetails__snackbar__details--image {
  margin-right: 1rem;
}
.p-cardetails__snackbar__details--image img {
  height: 100%;
}
.p-cardetails__snackbar__details--details {
  display: flex;
  flex-direction: column;
}
.p-cardetails__snackbar__details--heading {
  font-weight: bold;
  font-size: 20px;
}
.p-cardetails__snackbar__details--license {
  font-weight: normal;
}
.p-cardetails__snackbar__details--model {
  font-size: 14px;
}
.p-cardetails__snackbar__details--pricelabel {
  font-size: 16px;
}
.p-cardetails__snackbar__details--price {
  font-weight: bold;
}
.p-cardetails__snackbar__bidcta {
  background-color: #6c28a7;
  color: #fff;
  height: 75px;
  justify-content: space-between;
}
.p-cardetails__snackbar__bidcta--heading {
  color: #FFE300;
  font-size: 32px;
  text-transform: uppercase;
  font-weight: bold;
}
.p-cardetails__snackbar__bidcta--info {
  font-size: 14px;
  text-align: center;
}
.p-cardetails__snackbar__bidcta--cta {
  background-color: #FFE300;
  color: #000;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  padding: 15px;
  text-transform: uppercase;
}
.p-cardetails #thumbnail-carousel .splide__arrow {
  transform: translateY(-50%) scale(0.85);
}
.p-cardetails #thumbnail-carousel .splide__slide {
  border: 0;
}
.p-cardetails #thumbnail-carousel .splide__slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.p-cardetails .c-contact-buttons {
  display: flex;
  gap: 8px;
  justify-content: flex-start;
  align-items: flex-start;
}
.processlistitem__info__bidform--container {
  background-color: #6c28a7;
  border-radius: 25px;
  padding: 20px 40px;
  gap: 12px;
}

.processlistitem__bidbutton,
.processlistitem__bidbutton--desktop,
.bidform__removebidbuttoncontainer button {
  padding: 12px 27px;
}

.p-cardetails__inforow {
  margin-bottom: 0;
  font-family: rajdhani, sans-serif;
}

.p-cardetails__infovalue {
  line-height: 125%;
}

.p-cardetails__accordion > section {
  border-top: 1px solid #000;
}

.p-cardetails__expand {
  background: #ccc;
  padding: 12px;
}

.p-cardetails__expandicon {
  margin-right: 5px;
  transition: transform 0.3s;
}

.p-cardetails__expand.collapsed .p-cardetails__expandicon {
  transform: scaleY(-1);
}

#modal-processdetails___BV_modal_content_ {
  background-color: #E6EDF5;
}

.bidform__form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.p-cardetails__ownerhistory {
  background: #fff;
  padding: 1rem 0;
}

.p-cardetails__ownerhistorylist li {
  margin-bottom: 1rem;
}

@media screen and (min-width: 1001px) {
  .p-cardetails__accordion div[role=tabpanel] {
    display: block !important;
  }
  .p-cardetails__expand {
    background: none !important;
    pointer-events: none;
    padding-top: 0;
    padding-left: 0;
  }
  .p-cardetails__expandicon {
    display: none;
  }
  .p-cardetails__accordion > section {
    border-top: 0;
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  }
  .p-cardetails__cols--2col {
    grid-template-columns: 1fr 1fr;
    gap: 0 4rem;
  }
  .p-cardetails__inforow {
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .p-cardetails__ownerhistory {
    background: none;
    padding: 0;
  }
}
#thumbnail-carousel {
  max-width: 100%;
  opacity: 0;
  height: 0;
}
@media (min-width: 768px) {
  #thumbnail-carousel {
    height: auto;
    opacity: 1;
  }
}

.p-cardetails__snackbar {
  align-items: center;
  color: #fff;
}
.p-cardetails__snackbar .p-cardetails__snackbar__details {
  height: auto;
  flex: 1;
}
.p-cardetails__snackbar .p-cardetails__snackbar__details--license {
  display: block;
}
.p-cardetails__snackbar .p-cardetails__snackbar__details--details {
  display: block;
}
.p-cardetails__snackbar .p-cardetails__snackbar__details--image,
.p-cardetails__snackbar .p-cardetails__snackbar__details--model,
.p-cardetails__snackbar .p-cardetails__snackbar__details--pricelabel,
.p-cardetails__snackbar .bidform__removebidbuttoncontainer {
  display: none;
}
.p-cardetails__snackbar .p-cardetails__snackbarbidform {
  text-align: right;
}
.p-cardetails__snackbar .p-cardetails__snackbarbidform .processlistitem__info__bidform--container {
  display: none !important;
}
.p-cardetails__snackbar .processlistitem__bidbutton--desktop {
  display: none;
}
.p-cardetails__snackbar .bidform__hashighestbid ~ .processlistitem__bidbutton {
  display: none;
}
.p-cardetails__snackbar .processlistitem__inforow {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .p-cardetails__snackbar .p-cardetails__snackbar__details--image,
  .p-cardetails__snackbar .p-cardetails__snackbar__details--model,
  .p-cardetails__snackbar .p-cardetails__snackbar__details--pricelabel {
    display: block;
  }
  .p-cardetails__snackbar .p-cardetails__snackbar__details {
    height: 100%;
  }
  .p-cardetails__snackbar .p-cardetails__snackbar__details--license {
    display: inline;
  }
}
@media (min-width: 992px) {
  .p-cardetails__snackbar {
    color: #000;
  }
  .p-cardetails__snackbar .processlistitem__bidbutton {
    order: 2;
  }
  .p-cardetails__snackbar .processlistitem__info__bidform--container {
    background-color: transparent;
  }
  .p-cardetails__snackbar .p-cardetails__snackbarbidform {
    display: flex;
    align-items: center;
    background: var(--color-purple);
    border-radius: 50rem !important;
    padding: 15px 20px 15px 35px;
  }
  .p-cardetails__snackbar .processlistitem__infotext {
    margin-bottom: 0;
  }
  .p-cardetails__snackbar .processlistitem__infotext--nobidyet {
    max-width: 150px;
  }
  .p-cardetails__snackbar .processlistitem__info--bidformheader {
    margin-bottom: 0;
    font-weight: 700;
  }
  .p-cardetails__snackbar .p-cardetails__snackbarbidform .processlistitem__info__bidform--container {
    display: flex !important;
    flex-direction: row !important;
    align-items: center;
    gap: 1rem;
    padding-left: 0 !important;
    padding-right: 1rem !important;
  }
  .p-cardetails__snackbar .p-cardetails__snackbarbidform .processlistitem__info__bidform--container form {
    display: none;
  }
  .p-cardetails__snackbar .processlistitem__bidbutton--mobile {
    display: none;
  }
  .p-cardetails__snackbar .processlistitem__bidbutton--desktop {
    display: inline-block;
  }
  .p-cardetails__snackbar .p-cardetails .processlistitem__info__bidform--container {
    background: none;
  }
}
@media (min-width: 1200px) {
  .p-cardetails__snackbar {
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .p-cardetails__snackbar--container {
    background-color: #fff;
    color: #000;
    height: 120px;
    display: flex;
    align-items: center;
  }
}
.p-cardetails--nophotos {
  display: none;
}

.processlistitem__image {
  position: relative;
}

#infoblock-remarks .p-cardetails__inforow,
#infoblock-remarks_for_dealer .p-cardetails__inforow {
  white-space: pre-wrap;
}
#infoblock-remarks .p-cardetails__cols,
#infoblock-remarks_for_dealer .p-cardetails__cols {
  grid-template-columns: 1fr;
}